export const arrButtonsName = ["1W", "1M", "6M", "1Y"];

export const chartDiagram1Option = {
  chart: {
    height: 350,
    type: "area",
    width: "100%", // does not working
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
    },
  },
  colors: ["#f1b44c", "#80ecc5", "#8195f5", "#f14c4c", "#dcfb82", "#74d8f6"],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "left",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 12,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 10,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  xaxis: {
    type: "datetime",
  },
  tooltip: {
    x: {
      format: "dd/MM/yy HH:mm",
    },
  },
};
export const chartDiagram2Option = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: false,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
    },
    zoom: {
      enabled: true,
    },
  },
  colors: ["#f7d79e", "#ccd3f7", "#c2eddd"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 6,
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",

      columnWidth: 12,

      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "left",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 0,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 15,
      vertical: 10,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  fill: {
    opacity: 1,
  },
};

export const chartDiagram3Option = {
  chart: {
    type: "bar",
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  colors: ["#778beb", "#f4c370"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 12,
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",

      columnWidth: 12,

      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },

  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "bottom",
    horizontalAlign: "left",
    floating: false,
    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    fontWeight: 400,
    formatter: undefined,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 0,
    offsetY: 0,
    labels: {
      colors: undefined,
      useSeriesColors: false,
    },
    markers: {
      width: 12,
      height: 12,
      strokeWidth: 0,
      strokeColor: "#fff",
      fillColors: undefined,
      radius: 0,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    itemMargin: {
      horizontal: 40,
      vertical: 10,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  fill: {
    opacity: 1,
  },
};

export const chartDiagram3Option_chart2 = {
  chart: {
    type: "bar",
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
  },
  colors: ["#f4c370"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,

      columnWidth: 5,

      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },

  legend: {
    position: "bottom",
  },
  fill: {
    opacity: 1,
  },
};

export const chartDiagram4Option_chart1 = {
  chart: {
    type: "bar",
    stacked: true,
    // stackType: "100%", // fix
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
  },

  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      borderRadius: 12,
      borderRadiusApplication: "end",
      borderRadiusWhenStacked: "last",

      columnWidth: 12,

      dataLabels: {
        total: {
          enabled: false,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },

  legend: {
    position: "bottom",
    show: false,
  },
  fill: {
    opacity: 1,
  },
};

export const chartDiagram4Option_chart2 = (
  color2,
  categories2,
  heightLegend
) => {
  return {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: color2,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 5,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",

        columnWidth: 5,

        dataLabels: {
          total: {
            enabled: false,
            style: {
              fontSize: "13px",
              fontWeight: 900,
            },
          },
        },
      },
    },

    xaxis: {
      categories: categories2,
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false,
      fontSize: "14px",
      fontFamily: "Helvetica, Arial",
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: undefined,
      height: heightLegend, // add new
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 0,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 60,
        vertical: 10,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    fill: {
      opacity: 1,
    },
  };
};

export const headerDiagram1 = [
  {
    header: "Lieferterminverkürzungen",
    description: "Alle Lieferterminverkürzungen innerhalb des Zeitraums",
  },
  {
    header: "Lieferterminanfragen",
    description: "Bestellgebunden",
  },
  {
    header: "Lieferterminanfragen",
    description: "Unverbindlich",
  },
];
export const headerDiagram2 = [
  {
    color1: ["#ecc37c", "#f7d79e"],
    color2: ["#c29f7a", "#ccd3f7"],
    color3: ["#b8b960", "#c2eddd"],
    header: "Getätigte Lieferterminanfragen",
  },
  {
    color1: ["#ecc37c", "#f7d79e"],
    color2: ["#c29f7a", "#ccd3f7"],
    color3: ["#b8b960", "#c2eddd"],
    header: "Angefragte Lieferterminverkürzungen",
  },
];

export const headerDiagram3 = [
  "Anzahl Lieferterminanfragen",
  "Anzahl Lieferterminverkürzungen",
];

export const defaultSettingDiagram4 = {
  color1: ["#77cfeb", "#f68888"],
  color2: ["#77cfeb", "#f68888"],
  heightLegend: 40, // for prevent scroll in legend
  label: {
    transmitted_delivery_date_requests: "verbindliche Lieferterminanfragen",
    Delivery_date_requests_to_be_processed_by_dispatchers:
      "Lieferterminverkürzungen",
  },
  startDif: 105,
};

export const headerDiagram4 = ["Terminanfragen nach Kundennummer"];

export const defaultSettingDiagram5 = {
  color1: ["#34c38f", "#556ee6", "#f1b44c", "#55c3e6", "#f46a6a"],
  color2: ["#34c38f", "#556ee6", "#f1b44c", "#55c3e6", "#f46a6a"],
  heightLegend: 75, // for prevent scroll in legend
  label: {
    Number_of_delivery_date_reductions_to_process:
      "Anzahl der zu bearbeitenden Lieferterminverkürzungen",
    Number_of_successful_truncations: "Anzahl der offenen Anfragen",
    Number_of_open_requests: "Anzahl der abgelehnten Anfragen",
    Number_of_responses_with_text_date: "Anzahl der erfolgreichen Verkürzungen",
    Number_of_denied_requests: "Anzahl der Rückmeldungen mit Text / Datum",
  },
  startDif: 138,
};

export const headerDiagram5 = [
  "Terminanfragen nach Disponent",
  "Terminanfragen nach Lieferant",
];
